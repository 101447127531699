<template>
  <section>
    <div class="row">
      <q-item
        v-for="item in items"
        :key="item.id"
        :focused="checkoutStore.pickupLocation?.id == item.id"
        class="col-12 col-sm-6"
        tag="label"
        clickable
      >
        <q-item-section
          :avatar="$q.screen.gt.md"
          side
        >
          <q-radio
            :model-value="checkoutStore.pickupLocation?.id"
            :val="item.id"
            :dense="$q.screen.lt.lg"
            class="col-12 col-sm-6"
            @update:model-value="checkoutStore.pickupLocation = item"
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row q-gutter-x-xs">
            <span class="text-weight-bold">
              {{ locationLabel(item.id) ?? item.name }}
            </span>
          </q-item-label>
          <q-item-label>
            {{
              concat(
                ', ',
                concat(', ', item.street, item.street2),
                item.city,
                item.state,
                item.postcode,
              )
            }}
          </q-item-label>
          <q-item-label>
            {{ phone(item.phoneNumber) }}
          </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            :icon="mdiMapMarker"
            round
            @click.stop="
              navigateTo(locationUrl(item.id), {
                external: true,
                open: { target: '_blank' },
              })
            "
          />
        </q-item-section>
      </q-item>

      <!-- <q-item
        v-if="!items.find((i) => i.id == '104')"
        class="col-12 col-sm-6"
      >
        <q-item-section avatar>
          <q-radio
            :model-value="undefined"
            val="104"
            class="col-12 col-sm-6"
            disable
          />
        </q-item-section>
        <q-item-section>
          <q-item-label class="row q-gutter-x-xs">
            <span class="text-weight-bold">
              {{ locationLabel('104') }}
            </span>
            <span class="text-grey"> (Coming Soon) </span>
          </q-item-label>
          <q-item-label>
            2301 Nelson Miller Pkwy, Louisville, KY, 40223
          </q-item-label>
          <q-item-label> (502) 348-3594 </q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            :icon="mdiMapMarker"
            round
            @click.stop="
              navigateTo(locationUrl('104'), {
                external: true,
                open: { target: '_blank' },
              })
            "
          />
        </q-item-section>
      </q-item> -->
    </div>

    <q-stepper-navigation>
      <q-btn
        :disable="!canContinue || quoteStore.pending"
        color="primary"
        label="Continue"
        square
        @click="checkoutStore.goToNext()"
      />
    </q-stepper-navigation>
  </section>
</template>

<script lang="ts" setup>
import { mdiMapMarker } from '@quasar/extras/mdi-v7';

const checkoutStore = useCheckoutStore();
const quoteStore = useQuoteStore();
const { locationLabel, locationUrl } = useBusiness();
const { phone, concat } = useFormatting();

const items = computed(() => quoteStore?.data?.pickupLocations ?? []);

const canContinue = computed(
  () => !quoteStore.error && !!checkoutStore.pickupLocation,
);
</script>
